import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import AdminRoutes from './AdminRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import AdminNavBar from './components/common/AdminNavBar';
import AdminSideBar from './components/common/AdminSideBar';
import { SnackbarProvider } from 'notistack';
import Routers from './routers/Routers';
import OfflineRoutes from './routers/OfflineRoutes';
import {getclinicorder} from '../app/store/actions/clinic';
import VetRoutes from './routers/VetRoutes';
import clinicordercountDB from './DB/clinicOrderCount';
import OfflineFooter from './components/common/OfflineFooter';

export const AuthContext = React.createContext();
export const OrderContext = React.createContext();


function App()  {
  const [orderCount, setOrderCount] = useState(0)
  const [isLogin, setIsLogin]= useState(false)

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || null) 
    const token = JSON.parse(localStorage.getItem('token') || null)
    const isAdmin = JSON.parse(localStorage.getItem('isAdmin') || null)
    const isClinic = JSON.parse(localStorage.getItem('isClinic') || null)
    const isVet = JSON.parse(localStorage.getItem('isVet') || null);
    const isOnline = JSON.parse(localStorage.getItem('isOnline') || null);
    if(token){
      dispatch({
        type: 'LOGIN',
        payload: {
          token:token,
          isAdmin:isAdmin,
          isClinic:isClinic,
          isVet:isVet,
          isOnline: isOnline,
          hasOfflineData: "Maybe",
          userDetail:{
            ...user,
            userEmail:user.email
          }
        }
      })
    }
    
  }, [])
  
  const initialState = {
    isAuthenticated: false,
    user: null,
    userId: null,
    userEmail: null,
    token: null,
    isAdmin:null,
    isOnline: true
  };

  const clearAllTheData = (myDB) => {
    myDB._storeNames.forEach(async(tableName) => {
      const data = await myDB[tableName].clear();
    });
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "LOGIN":
        setIsLogin(true)
        localStorage.setItem("user", JSON.stringify(action.payload.userDetail));
        localStorage.setItem("userId", JSON.stringify(action.payload.userDetail.userId));
        localStorage.setItem("userEmail", JSON.stringify(action.payload.userDetail.email));
        localStorage.setItem("token", JSON.stringify(action.payload.token));
        localStorage.setItem("isAdmin", JSON.stringify(action.payload.isAdmin?action.payload.isAdmin:false));
        localStorage.setItem("isClinic", JSON.stringify(action.payload.isClinic? action.payload.isClinic:false));
        localStorage.setItem("isVet", JSON.stringify(action.payload.isVet?action.payload.isVet: false));
        localStorage.setItem("isOnline", JSON.stringify((action.payload.isOnline === false) ? action.payload.isOnline : true));
        if(action.payload.isOnline !== false){
          getclinicorder(action.payload.token, action.payload.userDetail.clinicId).then(({data}) => {
            setOrderCount(data.count);
          }).catch(err => {
            console.log(err)
          })
        }
        if(!action.payload.hasOfflineData){
          clearAllTheData(clinicordercountDB);
        }
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.userDetail,
          userId: action.payload.userDetail.userId,
          userEmail: action.payload.userDetail.email,
          token: action.payload.token,
          isAdmin: action.payload.isAdmin?action.payload.isAdmin:false,
          isClinic:action.payload.isClinic? action.payload.isClinic:false,
          isVet: action.payload.isVet?action.payload.isVet: false,
          isOnline: (action.payload.isOnline === false) ? action.payload.isOnline : true,
          hasOfflineData: action.payload.hasOfflineData === true
        };
      case "LOGOUT":
        localStorage.clear();
        setIsLogin(false)
        // clearAllTheData(clinicordercountDB);
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          userId: null,
          userEmail: null,
          isAdmin:null,
        };
      case "OFFLINE":
        localStorage.setItem("isOnline", false);
        return {
          ...state,
          hasOfflineData: "Maybe",
          isOnline:false
        };
      case "ONLINE":
        localStorage.setItem("isOnline", true);
        return {
          ...state,
          hasOfflineData: true,
          isOnline: true
        };
      default:
        return state;
    }
  };
  const changeOrderCount = (order_count) =>{ 
    console.log('ordercont', order_count)
    setOrderCount(order_count)
  }
  
  const [state, dispatch] = React.useReducer(reducer, initialState);
    if(!state.isOnline && (state.isClinic || state.isVet)){
      return (
        <OrderContext.Provider value={{order_count:orderCount, set_order_count : changeOrderCount}}>
          <AuthContext.Provider value={{state, dispatch}}>
            <div>
              <OfflineDashboard isClinic={state.isClinic} />
            </div>
          </AuthContext.Provider>
        </OrderContext.Provider>
      )
    }
    if(state.isAuthenticated && state.isAdmin){
      return (
        <AuthContext.Provider value={{state, dispatch}}>
          <div>
            <DashBoard isAdmin={state.isAdmin} />
          </div>
        </AuthContext.Provider>
      )
      }else if(state.isAuthenticated && state.isClinic){
        return (
          <OrderContext.Provider value={{order_count:orderCount, set_order_count : changeOrderCount}}>
            <AuthContext.Provider value={{state, dispatch}}>
              <div>
                <ClinicDashboard isClinic={state.isClinic} />
              </div>
            </AuthContext.Provider>
          </OrderContext.Provider>
          
        )
      }else if(state.isAuthenticated && state.isVet){
        return (
          <OrderContext.Provider value={{order_count:orderCount, set_order_count : changeOrderCount}}>
            <AuthContext.Provider value={{state, dispatch}}>
              <div>
                <VetDashboard isVet={state.isVet} />
              </div>
            </AuthContext.Provider>
          </OrderContext.Provider>
        )
      } else{
        return (
          <AuthContext.Provider value={{state, dispatch}}>
            <div>
              <AdminRoutes />
            </div>
          </AuthContext.Provider>
        )
      }
}

function DashBoard(props) {
  return (
    <SnackbarProvider>
      <div className="container-scroller">
        <Navbar/>
        <div className="container-fluid page-body-wrapper">
          <Sidebar isAdmin={props.isAdmin}/>
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes isAdmin={props.isAdmin}/>
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  )
}
function ClinicDashboard(props)  {
  return (
    <SnackbarProvider>
      <div className="container-scroller">
        <AdminNavBar/>
        <div className="container-fluid page-body-wrapper">
          <AdminSideBar/>
          <div className="main-panel">
            <div className="content-wrapper">
              <Routers />
            </div>
          </div>
        </div>
      </div>
    </SnackbarProvider>

  )
}

function VetDashboard(props)  {
  return (
    <SnackbarProvider>
      <div className="container-scroller">
        <AdminNavBar/>
        <div className="container-fluid page-body-wrapper">
          <AdminSideBar/>
          <div className="main-panel">
            <div className="content-wrapper">
              <VetRoutes />
            </div>
          </div>
        </div>
      </div>
    </SnackbarProvider>

  )
}

function OfflineDashboard(props)  {
  return (
    <SnackbarProvider>
      <div className="container-scroller">
        <AdminNavBar/>
        <div className="container-fluid page-body-wrapper">
          <AdminSideBar/>
          <div className="main-panel">
            <div className="content-wrapper">
              <OfflineRoutes />
            </div>
            <OfflineFooter />
          </div>
        </div>
      </div>
    </SnackbarProvider>

  )
}
export default withRouter(App);