import React, { useState, useContext, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router'
import {Button} from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync';
import ChangePassword from './ChangePassword';
import {sendSOS} from '../../store/actions/common'
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash'
import { AuthContext } from "../../App";
import {OrderContext}  from "../../App"
import { useSnackbar } from 'notistack';
import {getclinicorder, getAllProvinces, getBulkEvaluationData, getClinic, getBulkProducerData, getBulkBullData} from '../../store/actions/clinic';
import { getAllTreatmentList, getAllBreed, getAllAbnormalityList } from  '../../store/actions/evaluation'
import UpdateVetDialog from '../vet/UpdateVetDialog'
import {getCurrentVet, getClinicVets} from '../../store/actions/vet'
import { sendOffline, sendOnline } from '../../store/actions/auth';
import { getProducer, getSingleProducer, getProducerBullDetails, getVetProducers } from '../../store/actions/producer';
import VerifyEmailModal from './VerifyEmailModal';
import SyncDataModal from './SyncDataModal';
import CheckOTP from './CheckOTP';
import StatusModal from './StatusModal';
import {putClinicordercount, putallProvinces, putOwninformation, 
  putBulkProducers, puttreatmentlist,
  putbreedlist, putBulkBulls, putBulkEvaluations,
  putproducerdetails, putvetsdetails,
  putabnormalitylist, putvetList} from '../../indexedDB';
// import breadcrumbStyle from '../../../assets/styles/components/breadcrumbStyle.scss';

function Navbar(props) {
  const orderContext = useContext(OrderContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  // const producer_id = location.state.id?location.state.id:location.state.producer_id;
  const { state: authState, dispatch } = useContext(AuthContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false)
  const [openUpdateVet, setOpenUpdateVet] = useState(false)
  const [currentVet, setCurrentVet] = useState([]);
  const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);
  const [openSyncDataModal, setOpenSyncDataModal] = useState((authState.hasOfflineData === true) ? authState.hasOfflineData : false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openOTPModal, setopenOTPModal] = useState(false);
  const [hasInternetConnection, setHasInternetConnection] = useState(true);
  const styledDot = {width:'20px', height:'20px', borderRadius: '50%', display: 'inline-block', margin: '0 10px'};
  const styledDotGreen = { ...styledDot, background: '#37b737'};
  const styledDotRed = { ...styledDot, background: '#ff0000' };
  const [gotoOfflineClicked, setGotoOfflineClicked] = useState(false);
  const [resources, setResources] = useState([]);


  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };
  const handleChangePassword = (value) => {
    setOpen(value)
  }

  const handleUpdateVet = () => {
    setOpenUpdateVet(!openUpdateVet)
  }

  const handleLogout = (event) => {
    history.push('/')
    dispatch({type: "LOGOUT"});
  };

  const handleConnectionChange = (event) => {
    setHasInternetConnection(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    const {isOnline} = authState;
    if(isOnline){
      getclinicorder(authState.token, authState.user.clinicId).then(({data}) => {
        putClinicordercount(1, data);
      }).catch(err => {
        console.log(err)
      })
    }
   
    if(authState.isVet && authState.isOnline) {
      currentVetInfo()
    }

    return (() => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    });
  },[]);

  const handleEditClose = (value) => {
    setOpenUpdateVet(false);
    currentVetInfo();
  };

  const currentVetInfo = () => {
    getCurrentVet(authState.token, authState.userId).then(({data}) => {
      setCurrentVet(data.VetsDetail)
    }).catch(err => {
    })
  }

  const sendSOSToAdmin = () => {
    let token = authState.token
    sendSOS(token).then((data) => {
      enqueueSnackbar('A support email has been sent to the admin', {variant:"success"});
    }).catch(err => {
      console.log('err', err)
      enqueueSnackbar('Some error occured while sending support email to admin', {variant:"warning"});
    })
  };

  const onVerifyEmailModalToggled = (isOpened=true) => {
    // setOpenVerifyEmailModal(isOpened);
    setOpenStatusModal(true)
    // setOpenSyncDataModal(true)
    integrateApis();
  }

  const onSyncDataModalToggled = (isOpened = true) => {
    setOpenSyncDataModal(isOpened)
    if(gotoOfflineClicked){
      onVerifyEmailModalToggled(true)
    }
  }

  const onOpenOTPModalToggled = (isOpened) => {
    setopenOTPModal(isOpened);
  }

  const onOpenStatusModal = (isOpened) => {
    setOpenStatusModal(isOpened);
    if(!isOpened){
      const authObj = {
        ...authState,
        isOnline: false
      };
      sendOffline(authObj, dispatch);
    }
  }

  const integrateApis = async() => {
    let producerIdList = [];
    let resourceArray = [];
    const { isClinic, isVet } = authState;
    setLoading(true);
    const mainApis = [
      [getBulkProducerData, putBulkProducers],
      [getBulkBullData, putBulkBulls],
      [getBulkEvaluationData, putBulkEvaluations]
    ];
    if(isClinic){
      const clinicAPIsObject = {
        abnormalityList: [getAllAbnormalityList, putabnormalitylist, 'Abnormality List'],
        provinceList: [getAllProvinces, putallProvinces, 'Province List'],
        breedList: [getAllBreed, putbreedlist, 'Breed List'],
        clinicDetail: [getClinic, putOwninformation, 'Clinic Detail'],
        treatmentList: [getAllTreatmentList, puttreatmentlist, 'Treatment List'],
        vets: [getClinicVets, putvetList, 'Vet List'],
      };
      Promise.all(Object.entries(clinicAPIsObject).map(async([key, [apiName, indexedDBMethod, resourceName]]) => {
        let tempObj = {
          resource: resourceName,
          state: 'Success'
        }
        try{
          const {data} = await apiName(authState.token);
          indexedDBMethod(data[key]);
          if(key === "producercList"){
            producerIdList = data[key].map((producerData) => producerData.producer_id);
          }
        } catch(err){
          tempObj.state = 'Failed'
        }
        resourceArray.push(tempObj);
      })).then(() => {
        const newResourceData = [{
          resource: "Producer List",
          state: 'Success'
        },
        {
          resource: "Bull List",
          state: 'Success'
        },{
          resource: "Evaluation List",
          state: 'Success'
        }];
        Promise.all(mainApis.map(async([apiName, indexedDBMethodName]) => {
          try{
            const {data} = await apiName(authState.token);
            indexedDBMethodName(data);
          } catch(err){
            newResourceData.forEach((resObj) => {
              resObj.state = "Failed";
            })
          }
          
        })).then(() => {
          resourceArray.push(...newResourceData)
          setLoading(false);
          setResources(resourceArray);
          setOpenStatusModal(true);
        })
      });
    } else if(isVet){
      setLoading(true);
      const vatAPIObject = {
        abnormalityList: [getAllAbnormalityList, putabnormalitylist, 'Abnormality List'],
        provinceList: [getAllProvinces, putallProvinces, 'Province List'],
        breedList: [getAllBreed, putbreedlist, 'Breed List'],
        clinicDetail: [getClinic, putOwninformation, 'Clinic Detail'],
        treatmentList: [getAllTreatmentList, puttreatmentlist, 'Treatment List'],
        vets: [getClinicVets, putvetList, 'Vet List'],
        VetsDetail: [getCurrentVet, putvetsdetails, 'Vet Detail'],
      };

      Promise.all(Object.entries(vatAPIObject).map(async([key, [apiName, indexedDBMethod, resourceName]]) => {
        let tempObj = {
          resource: resourceName,
          state: 'Success'
        }
        try{
          const {data} = await apiName(authState.token, authState.userId);
          indexedDBMethod(data[key]);
          if(key === "producercList"){
            producerIdList = data[key].map((producerData) => producerData.producer_id);
          }
        }catch(err){
            tempObj.state = 'Failed';
        }
        resourceArray.push(tempObj);
      })).then(() => {
        const newResourceData = [{
          resource: "Producer List",
          state: 'Success'
        },
        {
          resource: "Bull List",
          state: 'Success'
        },{
          resource: "Evaluation List",
          state: 'Success'
        }];
        Promise.all(mainApis.map(async([apiName, indexedDBMethodName]) => {
          try{
            const {data} = await apiName(authState.token);
            indexedDBMethodName(data);
          } catch(err){
            newResourceData.forEach((resObj) => {
              resObj.state = "Failed";
            })
          }
          
        })).then(() => {
          resourceArray.push(...newResourceData)
          setLoading(false);
          setResources(resourceArray);
          setOpenStatusModal(true);
        // })
       });
      });
    }
  }

  const sendAppOffline = async() => {
    integrateApis();
  }

  const gotoOnline = () => {
    const authObj = {
      ...authState,
      isOnline: true
    };
    history.push('/');
    sendOnline(authObj, dispatch);
  }
 
    return (
      <nav className="admin-navbar navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}><img src="/images/logo-mini.png" alt="logo" /></a>
                  <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
                    <i className="mdi mdi-menu"></i>
                  </button>
                  <ul className="admin-dropdown navbar-nav navbar-nav-right ml-lg-auto">
                    <li className="nav-item  nav-profile border-0">
                      {/* <label class="switch">
                        <input type="checkbox" id="togBtn" onChange={onModeChanged}/>
                        <div class="slider round">
                          <span class="on">Online</span>
                          <span class="off">Offline</span>
                        </div>
                      </label> */}
                      {/* {!authState.isOnline ? (hasInternetConnection)
                          ? <span style={styledDotGreen}></span> 
                          : <span style={styledDotRed}></span> : ''} */}
                      {!authState.isOnline ? <Button variant="contained" color="secondary" onClick={gotoOnline}>
                          Go To Online Mode
                      </Button> : <Button variant="contained" color="secondary" onClick={() => {
                        onSyncDataModalToggled(true);
                        setGotoOfflineClicked(true);
                      }
                      }>
                          Go To Offline Mode
                      </Button>}
                    <span className="order-count"><strong>Order Count:<span className="count"> {orderContext.order_count}</span></strong></span>&nbsp;&nbsp;
                        <Button disabled={ !authState.isOnline}  variant="contained" color="secondary" onClick={() => onSyncDataModalToggled(true)}>
                            <SyncIcon /> Sync
                        </Button>
                        {
                          authState.isClinic && <Button disabled={!authState.isOnline} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" variant="outlined" >
                
                          <Link to='/orders'> Get More</Link>
                          </Button>
                        }
                        
                      <Dropdown alignRight>
                        <Dropdown.Toggle className="count-indicator bg-transparent" disabled={!authState.isOnline}>
                          {/* <span className="profile-text">Ritu</span> */}
                          <img className="profile-logout" className="img-xs rounded-circle" src={authState.isClinic ? '/images/C_icon.png' : '/images/V_icon.png'} alt="Profile" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="preview-list navbar-dropdown pb-3 user-logout">
                          <Dropdown.Item eventKey="1" onClick={() => handleChangePassword(true)}>Change Password</Dropdown.Item>
                          <li>
                            <a style={{textDecoration:'none', color:'#2a2e32'}} target="_blank" href={"mailto:" + 'bbs@wcabp.com'}>Support</a>
                          </li>
                          {
                            authState.isVet && <Dropdown.Item eventKey="2" onClick={()=>handleUpdateVet()}>Update Profile</Dropdown.Item>
                          }
                          <Dropdown.Item eventKey="3" onClick={() => handleLogout()}>Sign Out</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                  <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
                    <span className="mdi mdi-menu"></span>
                  </button>
                </div>
                <ChangePassword 
                  open={open}
                  handleChangePassword={handleChangePassword}
                />
                <VerifyEmailModal 
                  open={openVerifyEmailModal}
                  handleChangePassword={onVerifyEmailModalToggled}
                  emailId={authState.userEmail}
                  hadleOTPModal={onOpenOTPModalToggled}
                  enqueueSnackbar
                />

                <SyncDataModal 
                  open={openSyncDataModal}
                  handleSyncingDone={onSyncDataModalToggled}
                  token={authState.token}
                  enqueueSnackbar
                  isOffliceBtnClicked={gotoOfflineClicked}
                  userId={authState.userId}
                />
                <CheckOTP 
                  open={openOTPModal}
                  handleChangePassword={onOpenOTPModalToggled}
                  emailId={authState.userEmail}
                  enqueueSnackbar
                  token={authState.token}
                  sendAppOffline={sendAppOffline}
                />
                <StatusModal 
                  resources = {resources}
                  open={openStatusModal}
                  hadleOTPModal={onOpenStatusModal}
                  refetch={integrateApis}
                  isLoading={loading}
                />
                  { currentVet && openUpdateVet?
                    <UpdateVetDialog authState={authState} selectedProducers={[]} clinic_id={authState.user.clinicId} clinic_name={currentVet.clinic_name} vet_name={currentVet.name} id={currentVet.vet_id} open={openUpdateVet} signature={currentVet.signature} onClose={handleEditClose} /> : null
                }
              </nav>
    )
    
}

export default withRouter(Navbar);
