import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import {TableBody,TableHead, IconButton} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getPDF} from  '../../store/actions/evaluation'
import { AuthContext } from "../../App";
import ReactToPdf from 'react-to-pdf';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const SummaryReportsTable = (props) =>{
  const classes = useStyles();
  const { state: authState } = React.useContext(AuthContext);
  const ref = React.createRef();

  const handlePegignation = (page, limit) =>{
    props.onChangeLimit(page,limit)
  } 

  const downloadPdf = (evaluation) => {
    getPDF(authState.token, evaluation.evaluation_id).then(({data}) => {
      console.log('data', data)
      // var file = new Blob([data], {type: 'application/pdf'});
      //    var fileURL = URL.createObjectURL(file);
         window.open(data.url);
    }).catch(err => console.log(err))
  }

  const transformValue = (value) => {
    if(value =='G') {
      return 'Good';
    } else if(value == 'VG') {
      return 'Very Good';
    } else if(value == 'F') {
      return 'Fair';
    } else if(value == 'P') {
      return 'Poor';
    } else {
      return 'N/A';
    }
  }

  const changeStatus = (value) => {
    console.log("value", value)
    if(value === "VG") return "Very Good"
    if(value === "G") return "Good"
    if(value==="P") return "Poor"
    if(value==="F") return "Fair"
    return "N/A"
  }
  return(
    <div className="summery-search">
       <span style={{color:'red'}}>(* Any value not entered in the evaluation will show up as N/A. For best results of print, use 5 rows. Maximum count of "export to csv" is 10 records in one attempt.) </span>
    <div className={classes.root} >
     <Paper className={classes.paper} >
       <TableContainer id="reportsTable" >
         <Table
          ref={props._ref}
          id="reportTable"
         >
          <TableHead>
            <TableRow>
            <TableCell align="left" padding="checkbox">
              <Checkbox
                onChange={(event) => props.onChangedChecked(event, props.summary, null)}
                name="check_all"
              />
            </TableCell>
              <TableCell align="left">Evaluation No.</TableCell>
              <TableCell align="left" style={{width:300}}>Owner</TableCell>
              <TableCell align="left" style={{width:350}}>
                Identification 
                {
                  props.sortIdentification?
                  (
                    <IconButton onClick={() => props.sort_identification(props.summary)}>
                      <ArrowUpwardIcon fontSize="small" />
                    </IconButton>
                  ):
                  (
                    <IconButton onClick={() => props.sort_identification(props.summary)}>
                      <ArrowDownwardIcon fontSize="small" />
                    </IconButton>
                  )

                }
                
              </TableCell>
              <TableCell align="left" style={{width:300}}>Physical</TableCell>
              <TableCell align="left" style={{width:300}}>Semen Quality</TableCell>
              <TableCell align="left"style={{minWidth:300}}>Sperm Morphology</TableCell>
              <TableCell align="left">Classification
              {
                  props.sortClassification?
                  (
                    <IconButton onClick={() => props.sort_classification(props.summary)}>
                      <ArrowUpwardIcon fontSize="small" />
                    </IconButton>
                  ):
                  (
                    <IconButton onClick={() => props.sort_classification(props.summary)}>
                      <ArrowDownwardIcon fontSize="small" />
                    </IconButton>
                  )

                }
              </TableCell>
              <TableCell align="left">Comments</TableCell>
              <TableCell align="left">Status</TableCell>
              {/* <TableCell align="left" style={{width:300}}>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.summary.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                  >
                    <TableCell padding="checkbox" align="left">
                      <Checkbox
                        name={`check_${index}`}
                        checked={row[`check_${index}`]}
                        onChange={(event) => props.onChangedChecked(event, props.summary, index)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell>
                      {row.evaluation_id}
                      <a style={{color:'blue',cursor:'pointer'}} onClick={() => downloadPdf(row)}> <img src='/images/link-pdf-icon.png' ></img></a>
                    </TableCell>
                    <TableCell align="left">
                      <span ><strong>Name :</strong> {row.owner && row.owner.length>0 && row.owner[0].owner_name != '' ?row.owner[0].owner_name : 'N/A'}</span><br/>
                      <span ><strong>Email :</strong> {row.owner && row.owner.length>0 && row.owner[0].owner_email != '' ? row.owner[0].owner_email : 'N/A'}</span><br/>
                      <span ><strong>Phone :</strong> {row.owner && row.owner.length>0 && row.owner[0].owner_phone  != '' ? row.owner[0].owner_phone : 'N/A'}</span>
                    </TableCell>
                    <TableCell align="left" style={{width:350}}>
                        <span ><strong>Name :</strong> {row.identification && row.identification.ID != '' ? row.identification.ID : 'N/A'}</span> <br/>
                        <span ><strong>Breed :</strong> {row.identification && row.identification.Breed != '' ? row.identification.Breed : 'N/A'}</span><br/>
                        <span ><strong>Age :</strong> {row.identification && row.identification.bull_age != '' ? row.identification.bull_age : 'N/A'}</span>
                    </TableCell>
                    <TableCell align="left">
                      <span ><strong>Body Condition Score :</strong> {row.physical && row.physical.BSC != '' ? row.physical.BSC : 'N/A'}</span><br/>
                      <span ><strong>Scrotal Circumference :</strong> {row.physical && row.physical.SC != '' ? row.physical.SC : 'N/A'}</span><br/>
                      <span ><strong>Protrusion :</strong> {row.physical && row.physical.protrusion != '' ? row.physical.protrusion : 'N/A'}</span>
                    </TableCell>
                    <TableCell align="left">
                      <span ><strong>Volume :</strong> 
                      {row.ejaculations && row.ejaculations.length>0 && row.ejaculations[0].volume != '' ? (row.ejaculations[0].volume) : 'N/A' }, 
                          {row.ejaculations && row.ejaculations.length>1 && row.ejaculations[1].volume != '' ? (row.ejaculations[1].volume) : 'N/A' }
                        </span><br/>
                      <span ><strong>Density :</strong> 
                      {row.ejaculations && row.ejaculations.length>0 && row.ejaculations[0].density != '' ? transformValue(row.ejaculations[0].density) : 'N/A' }, 

                        {row.ejaculations && row.ejaculations.length>1 && row.ejaculations[1].density != '' ? transformValue(row.ejaculations[1].density) : 'N/A' }
                      </span><br/>
                      <span ><strong>Motility :</strong> 
                        {row.ejaculations && row.ejaculations.length>0 && row.ejaculations[0].gross_motility != '' ? transformValue(row.ejaculations[0].gross_motility) : 'N/A' }, 

                        {row.ejaculations && row.ejaculations.length>1 && row.ejaculations[1].gross_motility != '' ? transformValue(row.ejaculations[1].gross_motility) : 'N/A' }
                        </span>
                    </TableCell>
                    <TableCell align="left">
                      {
                          <>
                            <span>
                              <strong>Acrosome:</strong>{row.abnormalityPDFs && row.abnormalityPDFs.Acrosome && row.abnormalityPDFs.Acrosome.hasOwnProperty("percentage")? row.abnormalityPDFs.Acrosome.percentage:"N/A"}
                            </span><br/>
                            <span>
                            <strong>Acrosome:</strong>{row.abnormalityPDFs && row.abnormalityPDFs.Acrosome &&  row.abnormalityPDFs.Acrosome.hasOwnProperty('details') ? row.abnormalityPDFs.Acrosome.details && Array.isArray(JSON.parse(row.abnormalityPDFs.Acrosome.details)) && JSON.parse(row.abnormalityPDFs.Acrosome.details).map((detail, i) => {
                                  if(i === JSON.parse(row.abnormalityPDFs.Acrosome.details).length-1){
                                    return detail.acrosome?`${detail.acrosome}-${detail.acrosome_score}`:"N/A"
                                  }else{
                                    return detail.acrosome?`${detail.acrosome}-${detail.acrosome_score}`+", ":"N/A"+", "
                                  }
                              }):"N/A"}
                            </span><br/>
                            <span>
                              <strong>Detached:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Detached Heads"] && row.abnormalityPDFs["Detached Heads"].hasOwnProperty("percentage")? row.abnormalityPDFs["Detached Heads"].percentage:"N/A"}
                            </span><br/>
                            <span>
                            <strong>Detached:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Detached Heads"] && row.abnormalityPDFs["Detached Heads"].details &&  row.abnormalityPDFs["Detached Heads"].hasOwnProperty('details') ? Array.isArray(JSON.parse(row.abnormalityPDFs["Detached Heads"].details)) && JSON.parse(row.abnormalityPDFs["Detached Heads"].details).map((detail, i) => {
                                  if(i === JSON.parse(row.abnormalityPDFs["Detached Heads"].details).length-1){
                                    return detail.detached_heads?`${detail.detached_heads}-${detail.dh_score}`:"N/A"
                                  }else{
                                    return detail.detached_heads?`${detail.detached_heads}-${detail.dh_score}`+", ":"N/A"+", "
                                  }
                              }):"N/A"}
                            </span><br/>

                            <span>
                              <strong>Droplets:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Droplets"] && row.abnormalityPDFs.Droplets.hasOwnProperty("percentage")? row.abnormalityPDFs.Droplets.percentage:"N/A"}
                            </span><br/>
                            <span>
                            <strong>Droplets:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Droplets"] && row.abnormalityPDFs["Droplets"].details &&  row.abnormalityPDFs.Droplets.hasOwnProperty('details') ? Array.isArray(JSON.parse(row.abnormalityPDFs["Droplets"].details)) && JSON.parse(row.abnormalityPDFs["Droplets"].details).map((detail, i) => {
                                  if(i === JSON.parse(row.abnormalityPDFs["Droplets"].details).length-1){
                                    return detail.droplets?`${detail.droplets}-${detail.droplets_score}`:"N/A"
                                  }else{
                                    return detail.droplets?`${detail.droplets}-${detail.droplets_score}`+", ":"N/A"+", "
                                  }
                              }):"N/A"}
                            </span><br/>

                            <span>
                              <strong>Head:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Head"] && row.abnormalityPDFs.Head.hasOwnProperty("percentage")? row.abnormalityPDFs.Head.percentage:"N/A"}
                            </span><br/>
                            <span>
                            <strong>Head:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Head"] && row.abnormalityPDFs["Head"].details && row.abnormalityPDFs.Head.hasOwnProperty('details') ? Array.isArray(JSON.parse(row.abnormalityPDFs["Head"].details)) && JSON.parse(row.abnormalityPDFs["Head"].details).map((detail, i) => {
                                  if(i === JSON.parse(row.abnormalityPDFs["Head"].details).length-1){
                                    return detail.head?`${detail.head}-${detail.head_score}`:"N/A"
                                  }else{
                                    return detail.head?`${detail.head}-${detail.head_score}`+", ":"N/A"+", "
                                  }
                              }):"N/A"}
                            </span><br/>

                            <span>
                              <strong>Midpiece:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Midpiece"] && row.abnormalityPDFs.Midpiece.hasOwnProperty("percentage")? row.abnormalityPDFs.Midpiece.percentage:"N/A"}
                            </span><br/>
                            <span>
                            <strong>Midpiece:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Midpiece"] && row.abnormalityPDFs["Midpiece"].details &&  row.abnormalityPDFs.Midpiece.hasOwnProperty('details') ? Array.isArray(JSON.parse(row.abnormalityPDFs["Midpiece"].details)) && JSON.parse(row.abnormalityPDFs["Midpiece"].details).map((detail, i) => {
                                  if(i === JSON.parse(row.abnormalityPDFs["Midpiece"].details).length-1){
                                    return detail.midpiece?`${detail.midpiece}-${detail.midpiece_score}`:"N/A"
                                  }else{
                                    return detail.midpiece?`${detail.midpiece}-${detail.midpiece_score}`+", ":"N/A"+", "
                                  }
                              }):"N/A"}
                            </span><br/>

                            <span style={{fontSize:14}}>
                              <strong>Principal Piece:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Principal Piece"] && row.abnormalityPDFs["Principal Piece"].hasOwnProperty("percentage")? row.abnormalityPDFs["Principal Piece"].percentage:"N/A"}
                            </span><br/>
                            <span>
                            <strong>Principal Piece:</strong>{row.abnormalityPDFs && row.abnormalityPDFs["Principal Piece"] && row.abnormalityPDFs["Principal Piece"].details &&  row.abnormalityPDFs["Principal Piece"].hasOwnProperty('details') ? Array.isArray(JSON.parse(row.abnormalityPDFs["Principal Piece"].details)) && JSON.parse(row.abnormalityPDFs["Principal Piece"].details).map((detail, i) => {
                                  if(i === JSON.parse(row.abnormalityPDFs["Principal Piece"].details).length-1){
                                    return detail.principal_piece?`${detail.principal_piece}-${detail.pp_score}`:"N/A"
                                  }else{
                                    return detail.principal_piece?`${detail.principal_piece}-${detail.pp_score}`+", ":"N/A"+", "
                                  }
                              }):"N/A"}
                            </span><br/>
                          </>
                      }
                      {
                        // row.spermMorphology && row.spermMorphology.map(sperm=>{
                        //   return (
                        //     <>
                        //       <span ><strong>{sperm.abnormality_name} :</strong> {sperm.abnormality_value}</span><br/>
                        //     </>
                        //   )
                        // })
                      }
                    </TableCell>
                    <TableCell>
                      {row.classification != '' ? row.classification : 'N/A' }
                    </TableCell>
                    <TableCell>
                      {row.comments != '' ? row.comments : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {row.is_posted}
                    </TableCell>
                  </TableRow>
                );
              })}
              <br/>
            <TableRow>
              <TableCell className="tablefooterrow" colSpan={10}>
                <div className="tablefooter">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Range</InputLabel>
                  <Select
                    native
                    onClick={(event) => handlePegignation(1, event.target.value)}
                    label="Range"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={'all'}>All</option>
                  </Select>
                </FormControl>
                <IconButton 
                  style={{
                    marginLeft:20, 
                    backgroundColor:'#f68c31', 
                    color:'#ffffff',
                    width:32, 
                    height:32
                  }} 
                  className="lftbtn"
                  onClick={() => handlePegignation(props.page-1, props.limit)}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <span style={{marginLeft:20,color:'#fff',marginTop:10}}>{props.page} - {props.summaryPage}</span>
                <IconButton 
                  style={{
                    marginLeft:20, 
                    backgroundColor:'#f68c31', 
                    color:'#ffffff',
                    width:32, 
                    height:32
                  }} 
                  className="rightbtn"
                  onClick={() => handlePegignation(props.page+1, props.limit)}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </div>
  </div>
  )
  
  
}
export default SummaryReportsTable