import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { AuthContext } from '../../App';

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      return this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      return this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      return this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/clinic', state: 'clinicMenuOpen'},
      {path:'/tools', state: 'toolsMenuOpen'},
      {path:'/reports', state: 'reportsMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
    // console.log(this.props.isAdmin);

  }
  render () {
    // console.clear()
    // console.log("props", this.props)
    // console.log('is clinic adminsidebar', localStorage.getItem('isClinic'));
    // console.log('is clinic vet adminsidebar', localStorage.getItem('isVet'));
    return (
      <AuthContext.Consumer>
        { ({state}) => (
              <nav className="sidebar sidebar-offcanvas admin-sidebar" id="sidebar">
              <div className="text-center sidebar-brand-wrapper d-flex align-items-center nav-link" style={{cursor:'pointer'}} onClick={ () => this.props.history.push("/") }>
                {state.isOnline && <a className="sidebar-brand brand-logo" href><img src="/images/logo.png" alt="logo" /></a>}
                <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src="/images/logo-mini.png" alt="logo" /></a>
              </div>
              <ul className="nav">
                <li className="brand_menu_container" style={{cursor:'pointer'}}>
                  <div className={'nav-link'} onClick={ () => state.isOnline ? this.props.history.push("/") : this.props.history.push("/offline") } >
                  { state.isOnline && <center >
                    (<img className="normal-logo" src={JSON.parse(localStorage.getItem('isClinic')) ? '/images/clinic_logo.png' : '/images/V_Logo.png'}  alt="WCABP" title="WCABP" />
                    <img className="mini-logo" src={JSON.parse(localStorage.getItem('isClinic')) ? '/images/clinic_logo.png' : '/images/V_Logo.png'}  alt="WCABP" title="WCABP" />)
                    </center> }
                    {/* <center className="brand-word">
                      <span>CD MEDICAL INC.</span>
                    </center>
                    <center className="brand-description">
                      <span>2509 Louise St Saskatoon SK S7J 3L7</span>
                    </center> */}
                  </div>
                </li>
                <li className='nav-item top'>
                  <div className={'nav-link'} onClick={ () => state.isOnline ? this.props.history.push("/") : this.props.history.push("/offline") } data-toggle="collapse">
                    <i className="mdi mdi-home menu-icon"></i>
                    <span className="menu-title">DashBoard</span>
                    {/* <i className="menu-arrow"></i> */}
                  </div>
                  {/* <Collapse in={ this.state.clinicMenuOpen }>
                    <ul className="item-collapse nav flex-column sub-menu">
                      <li className="nav-item">
                      <Link className='nav-link'>Producers</Link>
                      </li>
                      <li className="nav-item">
                      <Link className='nav-link' to="/add-bull" >Bull</Link>
                      </li>
                      <li className="nav-item">
                      <Link className='nav-link'>Bulls Evaluation</Link>
                      </li>
                    </ul>
                  </Collapse> */}
                </li>
    
                {state.isOnline && <li className='nav-item'> 
                  <div className={'nav-link'} onClick={ () => this.toggleMenuState('toolsMenuOpen') } data-toggle="collapse">
                    <i className="mdi mdi-tools menu-icon"></i>
                    <span className="menu-title">Tools</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={ this.state.toolsMenuOpen }>
                    <ul  className="item-collapse nav flex-column sub-menu">
                      <li className="nav-item" style={{ display: (JSON.parse(localStorage.getItem('isClinic')) ? 'block' : 'none')}}>
                        <Link className='nav-link' to={"/orders"}>Orders</Link>
                      </li>
                      <li className="nav-item">
                      <Link className='nav-link' to={"/tools/pdfs"}>Information for Practitioners and Producers</Link>
                      </li>
                      <li className="nav-item" style={{ display: (!JSON.parse(localStorage.getItem('isVet')) ? 'block' : 'none') }}>
                        <Link className='nav-link' to={"/manage-vet"}>Manage Veterinarians</Link>
                      </li>
                      <li className="nav-item" style={{ display: (JSON.parse(localStorage.getItem('isAdmin')) ? 'block' : 'none')}}>
                        <Link className='nav-link' to={"/taxes"}>Manage Taxes</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>}
    
                {state.isOnline && <li className='nav-item'>
                  <div className='nav-link' onClick={ () => this.toggleMenuState('reportsMenuOpen') } data-toggle="collapse">
                    <i className="mdi mdi-file-search-outline menu-icon"></i>
                    <span className="menu-title">
                    Reports</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={ this.state.reportsMenuOpen }>
                    <ul className="item-collapse nav flex-column sub-menu">
                      <li className="nav-item">
                        <Link className='nav-link' to={"/reports"} >Search Reports</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>}
              </ul>
            </nav>
        )}
    </AuthContext.Consumer>);
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);
