import React, {useState, useEffect, useContext,useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator'
import {Grid,MenuItem} from '@material-ui/core';
import MuiPhoneNumber from "material-ui-phone-number";
import {withSnackbar} from 'notistack'
import {getClinic, getAllProvinces, updateClinic} from '../../store/actions/clinic'
import {Link, useHistory} from 'react-router-dom'
import { AuthContext } from "../../App";
import { getallProvinces } from '../../indexedDB';
import { updateOwninformation, getOwninformation } from '../../indexedDB';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const EditClinic = (props) => {
  const isValidZip =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

  let history = useHistory()
  const [provinces, setProvinces] = useState([])
  const formRef = useRef(null);
  let {state} = useContext(AuthContext)
  const [clinic, setClinic] = useState({
    name: '',
    email: '',
    address: '',
    username:'',
    phone: '',
    fax: '',
    city: '',
    province_id: '',
  })
  const classes = useStyles();
 
  useEffect(() => {
    getOwninformation().then(({data}) =>{
      setClinic(data.clinicDetail)
    }).catch(err => {
      console.log('err', err)
    })
    getallProvinces().then(({data}) => {
      setProvinces(data.provinceList)
    }).catch(err => {
      console.log("err", err)
    })
  },[])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidZipcode', (value) => {
      if (isValidZip.test(value)!==true) {
          return false;
      }
      return true;
    });
  }, [])

  const handleSubmit = () => {
    updateOwninformation(clinic.clinicId, clinic);
    history.push('/offline');
    props.enqueueSnackbar('Updated Clinic Successfully',{variant:"success"});
    // updateClinic(clinic, state.token).then(({data}) => {
    //   props.enqueueSnackbar('Updated Clinic Successfully',{variant:"success"});
    //   history.push('/offline')
    // }).catch(err => {
    //   console.log("err", err)
    //   props.enqueueSnackbar('Something went wrong',{variant:"warning"});
    // })
  }
  const onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    console.log("name, value",name, value )
    setClinic(prevForm => ({
      ...prevForm,
      [name]:value
    }))
  }

  function homeClinic() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Edit Clinic </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item">
   <Link to="/offline">
     <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
     <span>Home</span>
   </Link>
 </li> 
 <li className="breadcrumb-item active" aria-current="page">Edit Clinic</li>

            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  return (
    <>
      {homeClinic()}
      <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer" style={{padding:0}}>
        
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Edit Clinic</strong></span>
		    </div>
		    <div className="add-new-bull">
          <ValidatorForm ref={formRef} onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
            <div className={classes.root} style={{padding:'20px'}}>
              <Grid container spacing={1}>
                <Grid item xs sm={4}>
                  <TextValidator 
                    className="addProdColThree" 
                    required id="username" 
                    label="Username"
                    name="username"
                    value={clinic.username} 
                    onChange={(event)=>onChangeHandler(event)} 
                    variant="outlined" 
                    validators={['required', 'maxStringLength:32']} 
                    errorMessages={['this field is required', 'the max length is 32']}
                  />
                </Grid>
                <Grid item xs sm={4}>
                  <TextValidator 
                    className="addProdColThree" 
                    required id="clinic_name" 
                    label="Clinic Name"
                    name="name" 
                    onChange={(event)=>onChangeHandler(event)} 
                    value={clinic.name} 
                    variant="outlined" 
                    validators={['required', 'maxStringLength:32']} 
                    errorMessages={['this field is required', 'the max length is 32']}
                  />
                </Grid>
                <Grid item xs sm={4}>
                  <TextValidator 
                    className="addProdColThree" 
                    required id="email" 
                    name="email"
                    value={clinic.email}
                    onChange={(event)=>onChangeHandler(event)} 
                    label="Email" 
                    variant="outlined" 
                    validators={['required', 'isEmail']} 
                    errorMessages={['this field is required', 'email address is not valid']}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs sm={4}>
                  <SelectValidator 
                    className="addProdColThree" 
                    required
                    variant="outlined" 
                    label="Province"
                    name="province_id"
                    onChange={(event)=>onChangeHandler(event)} 
                    value={clinic.province_id}
                    placeholder="SELECT Province..." 
                    validators={['required']} 
                    errorMessages={['this field is required']}
                  >
                      <option>Province*...</option>
                      {provinces.map(province => (
                        <option value={province.provinceId} key={province.provinceId}>{province.province_name}</option>
                      ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs sm={4}>
                  <MuiPhoneNumber className="addProdColThree" required
                    onlyCountries	={['ca','us']}
                    name="phone"
                    label="Phone"
                    value={clinic.phone}
                    onChange={(event)=>setClinic({...clinic,phone:event})} 
                    variant="outlined"
                    data-cy="user-phone"
                    defaultCountry={"ca"}
                  />
                </Grid>
                <Grid item xs sm={4}>
                  <MuiPhoneNumber
                    className="addProdColTwo"
                    onlyCountries	={['ca','us']}
                    disableDropdown="true"
                    onChange={(event)=>setClinic({...clinic,fax:event})} 
                    name="fax"
                    label="Fax"
                    value={clinic.fax}
                    variant="outlined"
                    data-cy="user-phone"
                    defaultCountry={"ca"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs sm={4}>
                  <TextValidator 
                    className="addProdColThree" 
                    required id="address" 
                    label="Location" 
                    name="address"
                    onChange={(event)=>onChangeHandler(event)} 
                    value={clinic.address}
                    variant="outlined" 
                    validators={['required', 'maxStringLength:100']} 
                    errorMessages={['this field is required', 'the max length is 100']}
                  />
                </Grid>
                <Grid item xs sm={4}>
                  <TextValidator 
                    className="addProdColThree" 
                    required id="city" 
                    name="city"
                    onChange={(event)=>onChangeHandler(event)} 
                    value={clinic.city}
                    label="City" 
                    variant="outlined" 
                    validators={['required', 'maxStringLength:32']} 
                    errorMessages={['this field is required', 'the max length is 32']}
                  />
                </Grid>
                
                <Grid item xs sm={3}>
                  <TextValidator 
                    className="addProdColTwo" 
                    id="postcal-code" 
                    label="Postal Code" 
                    onChange={(event)=>onChangeHandler(event)} 
                    name="postal_code"
                    value={clinic.postal_code}
                    InputLabelProps={{
                      shrink: true
                    }}
                    required variant="outlined" 
                    validators={['required','isValidZipcode']} 
                    errorMessages={['this field is required', 'This Postal code is not valid']}
                  /> 
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs sm={12}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit" 
                  >
                    Update
                  </Button>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    type="submit" 
                    onClick={() => props.history.push('/offline')}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </ValidatorForm>
		    </div>
      </div>
    </>
  );
}

export default withSnackbar(EditClinic);
