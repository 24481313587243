import React from 'react';
import Grid from '@material-ui/core/Grid';


const DetailsClinic = (props) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs>
          <span><strong>Username:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Clinic Name:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Email:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Phone:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Fax:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Location:</strong></span>
        </Grid>
      </Grid>
      <hr style={{marginTop:0}}  />
      <Grid container spacing={3}>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.username}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.name}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.email}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.phone}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.fax}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.address}</span>
        </Grid>
      </Grid>
      <Grid container style={{marginTop:10}} spacing={3}>
        <Grid item xs>
          <span><strong>City:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Postal Code:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Province:</strong></span>
        </Grid>
        <Grid item xs>
          <span><strong>Total Producers:</strong></span>
        </Grid>
        <Grid item xs>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>
      <hr style={{marginTop:0}} />
      <Grid container spacing={3}>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.city}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.postal_code}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.name && props.clinic.province}</span>
        </Grid>
        <Grid item xs>
          <span style={{fontSize:14}}>{props.clinic && props.clinic.total_producer}</span>
        </Grid>
        <Grid item xs>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>
    </>
  );
}

export default DetailsClinic;
