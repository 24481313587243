import axios from './../axios'
import key from './key'
const pdfurl = process.env.REACT_APP_DOWNLOADPDF;

export const getAllAbnormalityList = async (token) =>{
  return await axios.get('/abnormalitylist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getAllTreatmentList = async (token) =>{
  return await axios.get('/treatmentlist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getAllVetList = async (token) =>{
  return await axios.get('/vetslist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getAllBreed = async (token) =>{
  return await axios.get('/breedlist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const addEvaluation = async (data,token) =>{
  return await axios.post('newevaluation',data, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const updateEvaluation = async (data,token) =>{
  return await axios.post('updateevaluation',data, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const singleEvaluation = async (token,id) =>{
  return await axios.get(`singleevaluation/${id}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getPDF = async (token,id) =>{
  let data = {
    "x_api_key" : key,
    "Authorization" : token,
    "id" : id
  }
  return await axios.post(pdfurl,{...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}