import React,  {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table,Button,TextField} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import _, { isNumber } from 'lodash'
import { useRowSelect } from 'react-table';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const SummaryForm = (props) => {
  const classes = useStyles();
  const [breedSummary, setBreedSummary] = useState([])
  const [classAverage, setClassAverage] = useState([])
  const [scrotumAverage, setScrotumAverage] = useState('')
  useEffect(() => {
    summaryAverageSetter(props.summary)
  }, [props.summary])
  const summaryAverageSetter = (summaryreports) => {
    let results = _.cloneDeep(summaryreports)
    let summaryBreeds = []
    var classifications = [];
    var total_scrotum = 0;
    for(let res of results) {
      classifications.push(res['classification'])
      summaryBreeds.push(res['identification'].Breed)
      total_scrotum += parseInt(res['physical'].SC)
    }
    // console.log('scrotum count', total_scrotum/summaryreports.length)
    setScrotumAverage((total_scrotum/summaryreports.length).toFixed(2))
    var breed_average_map = summaryBreeds.reduce((a, c) => (a[c] = a[c] || 0, a[c]++, a), {});

    var breed_average = Object.keys(breed_average_map).map(s => ({
      breed: s,
      count: breed_average_map[s]
    }));
    var classification_average_map = classifications.reduce((a, c) => (a[c] = a[c] || 0, a[c]++, a), {});

    var classification_avg = Object.keys(classification_average_map).map(s => ({
      class: s,
      count: classification_average_map[s]
    }));
    setBreedSummary(breed_average)
    setClassAverage(classification_avg)
    console.log(classification_avg);  
  }
  return (
    <TableContainer component={Paper}>
    <h4 style={{color:'#3f6cb2'}}><strong>Average scrotal circumference: {!isNaN(scrotumAverage) ? scrotumAverage : 'N/A'} </strong></h4>
    <span style={{color: 'red', fontSize:'11px',float:'left',width:'100%',padding:'0 0 10px 0'}}>(* This report is based on the current page search results.)</span>
    <Table aria-label="simple table" style={{width:'48%',float:'left',border:'1px solid #e0e0e0'}}>
      <TableHead>
        <TableRow>
        <TableCell align="left">Breeds</TableCell>
        <TableCell align="left"></TableCell>
          {/* className={classes.table} <TableCell align="left">Classifications</TableCell> */}
        </TableRow>
        </TableHead>
      <TableBody>
      {
            breedSummary.map(bs => 

              (<TableRow>
                <TableCell component="th" scope="row">
                  <span >{bs.breed == ''? 'N/A' :bs.breed} </span><br/>
                  {/* <span >Breed 1 : (50%)</span><br/> */}
                </TableCell>
                <TableCell component="th" scope="row">
                  <span > {bs.count} : 1 ({(100/bs.count).toFixed(2)})%</span><br/>
                </TableCell>
              </TableRow>))
          }
        
      </TableBody>
    </Table>
    <Table  aria-label="simple table" style={{width:'48%',float:'right',border:'1px solid #e0e0e0'}}>
      <TableHead>
        <TableRow>
        <TableCell align="left">Classifications</TableCell>
        <TableCell align="left"></TableCell>
          {/*className={classes.table} <TableCell align="left">Classifications</TableCell> */}
        </TableRow>
        </TableHead>
      <TableBody>
      {
            classAverage.map(bs => 

              (<TableRow>
                <TableCell component="th" scope="row">
                  <span >{bs.class == ''? 'N/A' :bs.class} </span><br/>
                  {/* <span >Breed 1 : (50%)</span><br/> */}
                </TableCell>
                <TableCell component="th" scope="row">
                  <span > {bs.count} : 1 ({(100/bs.count).toFixed(2)})%</span><br/>
                </TableCell>
              </TableRow>))
          }
        
      </TableBody>
    </Table>
  </TableContainer>
    
  );
}

export default SummaryForm;
