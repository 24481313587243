import React, {useEffect, useState, useContext } from 'react';
import {makeStyles, Button} from '@material-ui/core';
import {Link} from 'react-router-dom'
import { AuthContext } from "../../App"
import {withSnackbar} from 'notistack'
import {useLocation, useHistory} from 'react-router-dom'
import SummaryReportsTable from '../../components/reports/SummaryReportsTable'
import {summaryReports, averageDetails} from '../../store/actions/report'
import SummaryForm from '../../components/reports/SummaryForm';
import EmailReport from '../../components/reports/EmailReport';
import {getPDF} from  '../../store/actions/evaluation'
import ReactToPdf from 'react-to-pdf';

import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: '100%',
  },
}));

const SummaryReparts = (props) => {
  let history = useHistory()
  const [summary, setSummary] = useState([])
  const [summaryPage, setSummaryPage] = useState(0)
  const [selectedBreeds, setSelectedBreeds] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [sortIdentification, setSortIdentification] = React.useState(true)
  const [sortClassification, setSortClassification] = React.useState(true)
  const ref = React.createRef();
  let location = useLocation()
  let {state} = useContext(AuthContext)

  useEffect(() => {
    let evaluation=JSON.parse(location.state.search.evaluations)
    if(Array.isArray( evaluation) && evaluation.length>0){
      setSummary(evaluation)
    }else{
      let url='reportresult?clinic_id='+evaluation.clinic_name+'&vet_id='+evaluation.vet_name+'&bull='+evaluation.searchByBull+'&producer_id='+evaluation.ownerName
      +'&breed_id='+evaluation.breed+'&date_start='+evaluation.evaluationFromDate+'&date_end='+evaluation.evaluationToDate+'&birthdate_start='+evaluation.selectedFromDate+'&birthdate_end='
      +evaluation.selectedToDate+'&age_from='+evaluation.ageFrom+'&age_to='+evaluation.ageTo+'&exam='+evaluation.soundnessDefects
      +'&scrotal_from='+evaluation.sizeFrom+'&scrotal_to='+evaluation.sizeTo+'&classification='+evaluation.classification+'&isposted='+evaluation.reportStatus+'&page='+page+'&limit='+limit;
      summaryReports(state.token,url).then(({data}) =>{
        if(data.reportsResults.length > 0) {
          let results = data.reportsResults.filter((result, i) => {
            result[`check_${i}`] = false
            return result
          })
          setSummary(results)
        }
        
        if(data.total%5 === 0){
          setSummaryPage(data.total/5)
        }else{
          setSummaryPage(Math.floor(data.total/5)+1)
        }
      })
    }
  }, [])


  const onChangeLimit = (number_page, limit_page) => {
    if(number_page > summaryPage) return 
    if(number_page<1) return
    let evaluation=JSON.parse(location.state.search.evaluations)
    if(Array.isArray( evaluation) && evaluation.length>0){
      setSummary(evaluation)
    }else{
      let url='reportresult?clinic_id='+evaluation.clinic_name+'&vet_id='+evaluation.vet_name+'&bull='+evaluation.searchByBull+'&producer_id='+evaluation.ownerName
      +'&breed_id='+evaluation.breed+'&date_start='+evaluation.evaluationFromDate+'&date_end='+evaluation.evaluationToDate+'&birthdate_start='+evaluation.selectedFromDate+'&birthdate_end='
      +evaluation.selectedToDate+'&age_from='+evaluation.ageFrom+'&age_to='+evaluation.ageTo+'&exam='+evaluation.soundnessDefects
      +'&scrotal_from='+evaluation.sizeFrom+'&scrotal_to='+evaluation.sizeTo+'&classification='+evaluation.classification+'&isposted='+evaluation.reportStatus+'&page='+number_page+'&limit='+limit_page;
      summaryReports(state.token,url).then(({data}) =>{
        let results = data.reportsResults.filter((result, i) => {
          result[`check_${i}`] = false
          return result
        })
        setSummary(results)
        setSummaryPage(data.total)
        if(data.total%limit_page === 0){
          setSummaryPage(data.total/limit_page)
        }else{
          setSummaryPage(Math.floor(data.total/limit_page)+1)
        }
      })
    }
    setLimit(limit_page)
    setPage(number_page)
    
  }

  const onChangedChecked = (event, all_summary, index) => {
    let name = event.target.name
    let temp_all_summary = _.cloneDeep(all_summary)
    let tempSummary;
    if(name!=="check_all"){
      temp_all_summary[index][name] = !temp_all_summary[index][name]
      tempSummary = temp_all_summary
    }else{
      tempSummary = all_summary.map((singleSummary, i) => {
        singleSummary[`check_${i}`] = event.target.checked
        return singleSummary
      })
    }
    console.log('temp summary', tempSummary)
    setSummary(tempSummary)
    setSelectedBreeds(tempSummary.filter((row, i) => row[`check_${i}`]===true))
  }

  const sort_identification = (all_summary) =>{
    let tempSummary = all_summary.sort((a, b) => {
      if(a.identification.ID> b.identification.ID) 
        return !sortIdentification?1:-1
      else if(a.identification.ID < b.identification.ID)
        return !sortIdentification?-1:1
      else 
        return 0
    })
    setSummary(tempSummary)
    setSortIdentification(!sortIdentification)
  }
  
  const sort_classification = (all_summary) => {
    let tempSummary = all_summary.sort((a, b) => {
      if(a.classification> b.classification) 
        return !sortClassification?1:-1
      else if(a.classification < b.classification)
        return !sortClassification?-1:1
      else 
        return 0
    })
    setSummary(tempSummary)
    console.log(all_summary)
    setSortClassification(!sortClassification)
  }

  const exportPdf = () => {
    let tempSelectedBreeds = [];
    if(selectedBreeds.length > 0) {
      
      tempSelectedBreeds = _.cloneDeep(selectedBreeds)
    } else {
      
      tempSelectedBreeds = _.cloneDeep(summary)
    }

    for(let tsb in tempSelectedBreeds) {
      // console.log(tempSelectedBreeds[tsb])
      getPDF(state.token,tempSelectedBreeds[tsb].evaluation_id).then(({data}) => {
        console.log('data', data)
        // var file = new Blob([data], {type: 'application/pdf'});
        //    var fileURL = URL.createObjectURL(file);
           window.open(data.url);
      }).catch(err => console.log(err))
    }
  }



  const exportCSV = () => {
    let csv="";
    let tempSelectedBreeds = [];
    if(selectedBreeds.length > 0) {
      
      tempSelectedBreeds = _.cloneDeep(selectedBreeds)
    } else {
      
      tempSelectedBreeds = _.cloneDeep(summary)
    }
    // let csvContent =
    //     ['classification', 'comments','evaluation_id','identification','is_posted','owner','physical','semenQuality','spermMorphology'
    //     ]+'\n' ;
    //     tempSelectedBreeds.forEach(function(row) { 
    //       const values = Object.values(row);

    //         csvContent+=values.join(',');
    //         csvContent+="\n";
    //     });
    //     let fileName = 'summaryreport';
    //     const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    //     const a = document.createElement("a");
    //     a.href = URL.createObjectURL(new Blob([csvContent], { type: "text/csv;charset=utf-8;" }));
    //     a.setAttribute("download", finalFileName);
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);

    for(let i = -1; i<tempSelectedBreeds.length; i++){
      let keysCounter = 0
      if(i === -1){
        let head_keys= [
          "Evaluation No",	
          "Owner",	
          "Email",	
          "Phone",	
          "Identification",	
          "Breed",	
          "Age",	
          "score",	
          "Body Condition Score",	
          "Scrotal Circumference",	
          "Protrusion",	
          "Volume",	
          "Density",	
          "Motility",	
          "Sperm Morphology",
          "classification",
          "Comments",
          "Status",
        ]
        let head_keys_length = head_keys.length
        for(let j=0; j<head_keys_length; j++){
          console.log("head_keys[j]",head_keys[j])
          csv += head_keys[j]+(keysCounter+1< head_keys_length ? ',' : '\r\n')
          keysCounter++
        }
      }else{
        let row = tempSelectedBreeds[i]
        console.log("row", row)
        let row_keys = [
          row.evaluation_id?row.evaluation_id:"", 
          row.owner && row.owner && row.owner.length && row.owner[0].owner_name?row.owner[0].owner_name:"",
          row.owner && row.owner && row.owner.length && row.owner[0].owner_name?row.owner[0].owner_email:"",
          row.owner && row.owner && row.owner.length && row.owner[0].owner_name?row.owner[0].owner_phone:"",
          row.identification && row.identification.ID?row.identification.ID:"", 
          row.identification && row.identification.Breed?row.identification.Breed:"", 
          row.identification && row.identification.bull_age?row.identification.bull_age:"",
          row.physical && row.physical.score?row.physical.score:"",
          row.physical && row.physical.BSC?row.physical.BSC:row.physical.BSC,
          row.physical && row.physical.SC?row.physical.SC:"",
          row.physical && row.physical.protrusion?row.physical.protrusion:"",
          row.semenQuality && row.semenQuality.length>0 &&  getSemenQuality(row.semenQuality, "volume"),
          row.semenQuality && row.semenQuality.length>0 && getSemenQuality(row.semenQuality, "density"),
          row.semenQuality && row.semenQuality.length>0 && getSemenQuality(row.semenQuality, "gross_motility"),
          getabnormalityPDFs(row.abnormalityPDFs),
          row.classification?row.classification:"", 
          row.comments?row.comments:"", 
          row.is_posted?row.is_posted:"", 
        ]
        let row_keys_length = row_keys.length
        for(let j=0; j<row_keys_length; j++){
          csv += row_keys[j]+(keysCounter+1< row_keys_length ? ',' : '\r\n')
          keysCounter++
        }
      }
      keysCounter = 0
    }
    let link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    link.setAttribute('download', 'Summary_Report.csv');
    document.body.appendChild(link)
    document.querySelector('#download-csv').click()
    let t1 = document.getElementById("download-csv");
    t1.remove()
  }
  const getSemenQuality = (semenQuality, type) => {
    console.log("semen-quality", semenQuality, type)
    let string = ""
    semenQuality.forEach((semen,i) => {
      if(type === "density" || type === "gross_motility"){
        if(i === semenQuality.length-1){
          string += changeStatus(semen[type])
        }else{
          string += changeStatus(semen[type])+"-"
        }
      }else{
        if(i === semenQuality.length-1){
          string += semen[type]
        }else{
          string += semen[type]+"-"
        }
      }
      
    })
    return string
  }
  const getabnormalityPDFs = (abnormalityPDFs)=>{
    let string=""
    if(abnormalityPDFs && abnormalityPDFs.Acrosome && abnormalityPDFs.Acrosome.hasOwnProperty("percentage")){
      string=string+"Acrosome: "+ abnormalityPDFs.Acrosome.percentage+' '
    }else{
      string=string+"Acrosome: "+ "N/A"+' '
    }
    if(abnormalityPDFs && abnormalityPDFs["Detached Heads"] && abnormalityPDFs["Detached Heads"].hasOwnProperty("percentage")){
      string=string+"Detached Heads: "+ abnormalityPDFs["Detached Heads"].percentage+' '
    }else{
      string=string+"Detached Heads: "+ "N/A"+' '
    }
    if(abnormalityPDFs && abnormalityPDFs.Head && abnormalityPDFs.Head.hasOwnProperty("percentage")){
      string=string+"Head: "+ abnormalityPDFs.Head.percentage+' '
    }else{
      string=string+"Head: "+ "N/A"+' '
    }
    if(abnormalityPDFs && abnormalityPDFs.Midpiece && abnormalityPDFs.Midpiece.hasOwnProperty("percentage")){
      string=string+"Midpiece: "+ abnormalityPDFs.Midpiece.percentage
    }else{
      string=string+"Midpiece: "+ "N/A"+' '
    }
    if(abnormalityPDFs && abnormalityPDFs.Droplets && abnormalityPDFs.Droplets.hasOwnProperty("percentage")){
      string=string+"Droplets: "+ abnormalityPDFs.Droplets.percentage
    }else{
      string=string+"Droplets: "+ "N/A"+' '
    }
    if(abnormalityPDFs && abnormalityPDFs["Principal Piece"] && abnormalityPDFs["Principal Piece"].hasOwnProperty("percentage")){
      string=string+"Principal Piece: "+ abnormalityPDFs["Principal Piece"].percentage+' '
    }else{
      string=string+"Principal Piece: "+ "N/A"+' '
    }
    console.log("string", string)
    return string
    
  }
  const changeStatus = (value) => {
    console.log("value", value)
    if(value === "VG") return "Very Good"
    if(value === "G") return "Good"
    if(value==="P") return "Poor"
    if(value==="F") return "Fair"
    return "N/A"
  }
  function url_head() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Summary Reports </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/offline">
                  <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/offline/reports">Reports</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Search Result</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }

  useEffect(() => {

  },[])


  return (
    <>
      {url_head()}
      <div  className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <div>
            <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Summary Reports</strong></span>
            <Button style={{marginLeft:20}} onClick={() => history.push('/offline/reports')} size="medium" variant="contained" color="secondary">
              <span style={{fontSize: 12}}><strong>Back to Search</strong></span>
            </Button>
            {/* <Button onClick={() => exportPdf()} style={{float:"right"}} size="medium" variant="contained" color="secondary">
                <span style={{fontSize: 12}}><strong>
                 
                </strong></span>
            </Button> */}
            <ReactToPdf targetRef={ref} filename={`Detail_Report.pdf`} options={{
                orientation: 'landscape',
                unit: 'in',
                format: [1200, 1200]
              }}>
                {({toPdf}) => (
                  <Button color="primary" size="medium" style={{float:"right"}} onClick={toPdf} style={{float:'right'}} variant="contained">print</Button>
                )}
              </ReactToPdf> 
            <Button onClick={() => exportCSV()} style={{float:"right"}} size="medium" variant="contained" color="primary">
              <span style={{fontSize: 12}}><strong>Export to CSV (Summary Reports)</strong></span>
            </Button>
          </div>
		    </div>
        <div>
        <SummaryReportsTable
          _ref={ref}
          onChangeLimit={onChangeLimit}
          sort_identification={sort_identification}
          sort_classification={sort_classification}
          sortIdentification={sortIdentification} 
          sortClassification={sortClassification}
          summary={summary} 
          summaryPage={summaryPage}
          page={page}
          limit={limit}
          onChangedChecked={onChangedChecked}
        />
        </div>
      <SummaryForm summary={summary}  />
      <EmailReport selectedBreeds={selectedBreeds} />
		  </div>

    </>
  );
}

export default withSnackbar(SummaryReparts);
