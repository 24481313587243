import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, InputBase, IconButton,Button} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid';
import ProducerItems from '../../components/producer/ProducerItems';
import DetailsClinic from '../../components/clinic/DetailsClinic';
import {withSnackbar} from 'notistack'
import {getClinic} from '../../store/actions/clinic'
import { AuthContext } from "../../App"
import {Link,useHistory} from 'react-router-dom'
import { getProducer } from '../../store/actions/producer';
import breadcrumbStyle from '../../../assets/styles/components/breadcrumbStyle.scss';
import { useSnackbar } from 'notistack';
import { getBulkProducers, getOwninformation, deleteclinicProducers } from '../../indexedDB';
import MaterialTable from 'material-table';
import DeleteProducer from '../../components/producer/DeleteProducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ClinicDetails = (props) => {
  let history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let {state} = useContext(AuthContext)
  let context = useContext(AuthContext)
  const [clinic, setClinic] = useState(null)
  const classes = useStyles();
  const [producer, setProducer] = React.useState(null)
  const [producers, setProducers] = useState([])
  const [tempProducers, setTempProducers] = useState([])
  const [searchText, setSearchText] = useState("")
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    getOwninformation().then(({data}) =>{
      // getClinicordercount();
      // getOwninformation();
      setClinic(data.clinicDetail)
    }).catch(err => {
      console.log('err', err)
    })
    if(state.isClinic) {
      getBulkProducers(1).then(({data}) =>{
        setProducers(data.producercList)
        setTempProducers(data.producercList)
      }).catch(err=>{
        console.log("err", err)
      })
    } else if(state.isVet) {
      getBulkProducers(1).then(({data}) =>{
        // console.log('data in vets', data)
        setProducers(data.producercList)
        setTempProducers(data.producercList)
      }).catch(err=>{
        console.log("err", err)
      })
    }
  },[])

  const search_Producer = (event) => {
    setSearchText(event.target.value)
    let value = event.target.value
    let searchValues = producers.filter(producer => producer.name.toLowerCase().includes(value.toLowerCase()))
    setTempProducers(searchValues)
  }

  const handleDialog = (producer) => {
    setProducer(producer)
    handleProducerDialog(true)
  }

  const handleProducerDialog = (value) => {
    setOpen(value)
  }
  
  function homeClinic() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Clinic DashBoard </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                {state.isOnline && <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />}
                <span>Home</span>
              </li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }

  const deleteSubmit = () => {
    producer.producer_id = producer.producer_id || producer.ID
    deleteclinicProducers(producer.producer_id).then((data) => {
      enqueueSnackbar(`Producer with name ${producer.name} deleted successfully`,{variant:'success'});
      setOpen(false)
      const newProducers = producers.filter((item) => item.producer_id !== producer.producer_id);
      setProducers(newProducers);
      setTempProducers(newProducers)
    }).catch(err => {
      console.log('err', err)
      enqueueSnackbar('something wrong while deleting bull',{variant:'warning'});
    });
    return true;
  }

  return (
    <>
      {homeClinic()}
      <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Clinic Information</strong></span>
		    </div>
          <div className={classes.root}>
            <DetailsClinic clinic={clinic}/>
            <Grid>
              {
                state.isClinic ? (<Button style={{marginTop:20}} onClick={() => history.push('/offline/clinic/edit')} variant="contained" disabled="state.offline" color="secondary">
                Edit Clinic
              </Button>) : ''
              }
              
            </Grid>
          </div>
		    </div>
        {/* <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
          <div className="MuiToolbar-root MuiToolbar-regular mb-4 producer_search" style={{padding:15, }}>
            <span className={`${classes.producer_container} MuiTypography-root MuiTypography-h5 mb-0` }  style={{color:'#fff'}}
            ><strong>Producers</strong></span>&nbsp;
            
            <InputBase
              className={classes.input}
              placeholder="Search Producers"
              value={searchText}
              name="serch_text"
              onChange={search_Producer}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
            {
              state.isClinic ? (<Button style={{float:'right'}} variant="contained" color="primary" onClick={() => props.history.push('/offline/clinic/add-producer')}>
              Add Producer
            </Button>) : ''
            }
          </div>
          <ProducerItems producers = {tempProducers} />
        </div> */}
          <MaterialTable
            title='Producers'
            columns={[
            { title: 'Name', field: 'name', render: rowData => <Link
                to={{pathname: `/offline/clinic/producer/${rowData.producer_id || rowData.ID}`,
                
                    // state: {
                    //   id: rowData.producer_id,
                    //   name: rowData.name,
                    //   addr: rowData.address,
                    //   city: rowData.city,
                    //   province: rowData.province,
                    //   zip: rowData.postal_code,
                    //   phone: rowData.phone,
                    //   fax: rowData.fax,
                    //   email: rowData.email,
                    //   tproducers: rowData.total_bull,
                    //   clinic_name: location.state.name,
                    //   provinceList: province_list,
                      // clinicId: clinic_id
                    //  }
                    }}>
                    <u>{rowData.name}</u>
                </Link> },
              { title: 'Location', field: 'address' },
              { title: 'City', field: 'city' },
              { title: 'Province', field: 'province' },
              { title: 'Postal Code', field: 'postal_code' },
              { title: 'Phone', field: 'phone' },
              { title: 'Email', field: 'email' },
              { title: 'Fax', field: 'fax', hidden:true },
              { title: 'Total Bulls', field: 'total_bulls', hidden:true },
              { title: 'Id', field: 'producer_id', hidden:true },
            ]}

            data={tempProducers}
            // total_count={total_count}

            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit Producer',
                onClick: (event, rowData) => {
                  rowData.producer_id = rowData.producer_id || rowData.ID;
                  history.push(`/offline/clinic/producer/${rowData.producer_id}`)
                  // const found = producersData.find(e=>e.name===rowData.name);
                  // setOpenEditProducer(true);
                  // setProducerName(found.name);
                  // setProducerAddr(found.address);
                  // setProducerCity(found.city);
                  // setProducerProvince(found.province);
                  // setProducerZip(found.postal_code);
                  // setProducerPhone(found.phone);
                  // setProducerFax(found.fax);
                  // setProducerEmail(found.email);
                  // setProducerTotalBulls(found.total_bull);
                  // setProducerId(found.producer_id);
                }
              },
              {
                icon: 'delete',
                tooltip: 'Cannot Delete Producer in offline mode',
                disabled : true,
                onClick: (event, rowData) => {
                  // deleteproducerdetails(rowData.producer_id);
                  setOpen(true);
                  handleDialog(rowData)
                  // const found = producersData.find(e=>e.name===rowData.name);
                  // setOpenConfirmProducer(true);
                  // setProducerId(found.producer_id);
                }
              },
              {
                icon: 'search',
                tooltip: 'Producer Details',
                onClick: (event, rowData)=>{
                  rowData.producer_id = rowData.producer_id || rowData.ID;
                  history.push(`/offline/clinic/producer/${rowData.producer_id}`,
                  // { id: rowData.producer_id,
                  //   name: rowData.name,
                  //   addr: rowData.address,
                  //   city: rowData.city,
                  //   province: rowData.province,
                  //   zip: rowData.postal_code,
                  //   phone: rowData.phone,
                  //   fax: rowData.fax,
                  //   email: rowData.email,
                  //   tbulls: rowData.total_bull,
                  //   clinic_name: name,
                  //   provinceList: province_list,
                  //   clinicId: clinic_id
                  // }
                  )}
              },
              {
                icon: 'add',
                tooltip: state.isVet ? 'Vet can not add producer' : 'Add Producer',
                isFreeAction: true,
                disabled: state.isVet,
                onClick: ()=>history.push({
                  pathname: '/offline/clinic/add-producer',
                  // state: {clinicId: clinic_id, provinceList: province_list,province:province, clinic_name:name}
                })
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              // pageSize: page_size,
              headerStyle: {
                backgroundColor: 'transperant',
                color: '#23263d'
              }
            }}
          />
           <DeleteProducer 
              handleProducerDialog={handleProducerDialog}
              open={open}
              deleteSubmit={deleteSubmit}
              producer={producer}
            />
    </>
  );
}

export default withSnackbar(ClinicDetails);
